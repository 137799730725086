import { lazy } from 'react';

import * as ROLES from '@root/constants/roles';
import * as ROUTES from '@root/constants/navigation';
import AuthGuard from '@root/utils/route-guard/AuthGuard';
import MainLayout from '@root/layout/MainLayout';
import Loadable from '@root/ui-component/Loadable';

const GeneralSettings = Loadable(lazy(() => import('@root/pages/settings/General')));

const BoxsizesList = Loadable(lazy(() => import('@root/pages/boxsizes/BoxsizesList')));
const BoxsizesCreate = Loadable(lazy(() => import('@root/pages/boxsizes/BoxsizesCreate')));
const BoxsizesUpdate = Loadable(lazy(() => import('@root/pages/boxsizes/BoxsizesUpdate')));

const PrepReqList = Loadable(
  lazy(() => import('@root/pages/prepRequirements/PrepRequirementsList')),
);
const PrepReqCreate = Loadable(
  lazy(() => import('@root/pages/prepRequirements/PrepRequirementsCreate')),
);
const PrepReqUpdate = Loadable(
  lazy(() => import('@root/pages/prepRequirements/PrepRequirementsUpdate')),
);

const ReazonsArchive = Loadable(lazy(() => import('@root/pages/asins/ReazonsArchive')));
const AsinsLog = Loadable(lazy(() => import('@root/pages/settings/AsinsLog')));

// ==============================|| SETTINGS ROUTING ||============================== //

const SettingRoutes = {
  path: '/',
  element: (
    <AuthGuard allowed_roles={[ROLES.ADMIN]}>
      <MainLayout hasSidenav={true} />
    </AuthGuard>
  ),
  children: [
    {
      path: ROUTES.SETTINGS_GENERAL,
      element: <GeneralSettings />,
    },
    {
      path: ROUTES.BOXSIZES,
      element: <BoxsizesList />,
    },
    {
      path: ROUTES.BOXSIZES_NEW,
      element: <BoxsizesCreate />,
    },
    {
      path: ROUTES.BOXSIZES_UPDATE,
      element: <BoxsizesUpdate />,
    },
    {
      path: ROUTES.PREP_REQUIREMENTS,
      element: <PrepReqList />,
    },
    {
      path: ROUTES.PREP_REQUIREMENTS_NEW,
      element: <PrepReqCreate />,
    },
    {
      path: ROUTES.PREP_REQUIREMENTS_UPDATE,
      element: <PrepReqUpdate />,
    },
    {
      path: ROUTES.REAZONS_ARCHIVE,
      element: <ReazonsArchive />,
    },
    {
      path: ROUTES.ASIN_LOGS,
      element: <AsinsLog />,
    },
  ],
};

export default SettingRoutes;
