export const DASHBOARD = '/';

export const ASINS = '/asins';
export const ASINS_ARCHIVED = '/asins/archived';
export const ASINS_NEW = '/asins/asin/new';
export const ASINS_IMPORT_FROM_CSV = '/asins/import-from-csv/';
export const ASIN = '/asins/:id';

export const ASINS_OLD = '/settings/asins-old';
export const REAZONS_ARCHIVE = '/settings/reasons-to-archive';
export const ASIN_LOGS = '/settings/asins-logs';

export const INVENTORY = '/inventory';
export const INVENTORY_MOVEMENTS = '/inventory/movements/:id';

export const PRODUCTS = '/inventory';
export const PRODUCTS_NEW = '/inventory/new';
export const PRODUCTS_UPDATE = '/inventory/:id/update';
export const PRODUCTS_IMPORT_FROM_CSV = '/inventory/import-from-csv/';

export const PRODUCTS_CATALOG = '/inventory/catalog';
export const PRODUCTS_CATALOG_NEW = '/inventory/catalog/new';
export const PRODUCTS_CATALOG_UPDATE = '/inventory/catalog/:id/update';
export const PRODUCTS_CATALOG_IMPORT_FROM_CSV = '/inventory/catalog/import-from-csv/';

export const SUPPLIERS = '/suppliers';
export const SUPPLIERS_NEW = '/suppliers/new';
export const SUPPLIERS_UPDATE = '/suppliers/:id/update';
export const SUPPLIERS_IMPORT_BUYLIST_CSV = '/suppliers/:id/import-list-from-csv';

export const SUPPLIERS_CATALOG = '/suppliers/:id/catalog';
export const SUPPLIERS_CATALOG_NEW = '/suppliers/:id/catalog/new';
export const SUPPLIERS_CATALOG_UPDATE = '/suppliers/:id/catalog/:cat_id';

export const SUPPLIERS_AVAILABILITY = '/suppliers_availability/:id';

export const BOXSIZES = '/settings/box-sizes';
export const BOXSIZES_NEW = '/settings/box-sizes/new';
export const BOXSIZES_UPDATE = '/settings/box-sizes/:id/update';

export const PREP_REQUIREMENTS = '/settings/prep-requirements';
export const PREP_REQUIREMENTS_NEW = '/settings/prep-requirements/new';
export const PREP_REQUIREMENTS_UPDATE = '/settings/prep-requirements/:id/update';

export const SETTINGS_GENERAL = '/settings/general';

export const AMAZON_INVENTORY = '/amazon/inventory';

export const PURCHASE_ORDERS_NEW = '/purchase_orders/new';
export const PURCHASE_ORDERS_OPEN = '/purchase_orders';
export const PURCHASE_ORDERS_SUPPLIER_VIEW = '/purchase_orders/supplier_view/:id';
export const PURCHASE_ORDERS_PICKER_LIST = '/purchase_orders/picker_list';
export const PURCHASE_ORDERS_PACKER_LIST = '/purchase_orders/packer_list';

export const USERS = '/users';
export const USERS_NEW = '/users/user/new';
export const USERS_UPDATE = '/users/:id/update';

export const PROFILE = '/profile';

export const LOGIN = '/login';

export const FORGOT_PASSWORD = '/forgot-password';

export const CODE_VERIFICATION = '/code-verification';

export const RESET_PASSWORD = '/password-reset-confirm/MQ/:token';

export const CHECK_MAIL = '/check-mail';

export const DESIGN_TABLE = '/design/tables';

export const UNAUTHORIZED = '/unauthorized';

export const NOT_FOUND = '/404';
