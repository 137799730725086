import { Button, Spinner } from 'react-bootstrap';
import type { ButtonProps } from 'react-bootstrap';

import cls from '@root/utils/cls';

interface IconButtonProps extends ButtonProps {
  loading?: boolean;
  startIcon?: string;
  endIcon?: string;
  iconColor?: string;
}

const IconButton = (props: IconButtonProps) => {
  const {
    children,
    variant = 'primary',
    loading = false,
    disabled = false,
    startIcon,
    endIcon,
    iconColor,
    ...others
  } = props;

  const startIconNode = !loading ? (
    <span className={cls(`text-${iconColor}`, children && 'mr-2')}>
      <i className={`fe fe-${startIcon} fs-3`}></i>
    </span>
  ) : (
    <Spinner
      className="mr-2"
      as="span"
      animation="border"
      size="sm"
      role="status"
      aria-hidden="true"
    />
  );

  const endIconNode = (
    <span className={cls(`text-${iconColor}`, children && 'ml-2')}>
      <i className={`fe fe-${endIcon} fs-3`}></i>
    </span>
  );

  const btnDisable = disabled ? true : loading ? true : false;

  return (
    <Button variant={variant} disabled={btnDisable} {...others}>
      {startIcon && startIconNode}
      {!loading ? children : 'Loading...'}
      {endIcon && endIconNode}
    </Button>
  );
};

export default IconButton;
